/*  ===============================================
    TopBar
------------------------*/
.top_bar {
  width: 100%;
  z-index: 3;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .top_bar_icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .top_bar_contact_item {
    font-size: 13px;
    line-height: 18px;
    position: relative;
    margin-right: 30px;
    &:not(:last-child)::after {
      content: "";
      height: 17px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      right: -15px;
      top: 0;
      bottom: 0;
    }
    .top_bar_icon {
      i {
        color: $theme-SkinColor;
        font-size: 15px;
      }
    }
  }
}
.bg-theme-DarkColor {
  .top_bar_contact_item {
    &:not(:last-child)::after {
      background-color: rgba(255, 255, 255, 0.1);
    }
    color: rgba(255, 255, 255, 0.37);
  }
}

/*  ===============================================
    Header
------------------------*/
.header {
  .site-header-menu {
    .container-fluid {
      padding: 0 40px;

      @media (max-width: 1199px) {
        padding: 0 24px;
      }
    }
  }
  &.is-Sticky {
    .site-header-menu {
      z-index: 2;
      background-color: #fff;
      box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, 0.15);
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

/** SiteBrand(logo) **/
.site-branding {
  img {
    max-height: 75px;

    @media (max-width: 1199px) {
      max-height: 60px;
      width: auto;
    }
  }
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;

  @media (max-width: 1199px) {
    padding: 15px 0;
  }
}

/* header_extra */
.header_extra {
  > div {
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
  }
}
.header_search_content {
  button.close-search {
    position: absolute;
    right: 15px;
    padding: 0;
    color: #fff;
    font-size: 21px;
    border-radius: 0;
    box-shadow: unset;
  }
}
.header_search {
  .header_search_content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    z-index: 10;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    #searchbox {
      position: absolute;
      left: 0;
      right: 0;
      width: 620px;
      max-width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .search_query {
        background: none;
        border: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 1);
        height: 70px;
        padding: 0 60px 0 15px;
        font-weight: 400;
        font-size: 25px;
        width: 100%;
        color: #fff;
        letter-spacing: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        background-color: transparent;
      }
      input {
        &::placeholder {
          color: #fff;
        }
      }
    }
    .close_btn {
      i {
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        -o-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        height: 35px;
        width: 35px;
        display: block;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
      }
      &:hover {
        i {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
  .header_search_content.on {
    visibility: visible;
    opacity: 1;
    display: block;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .header_search_content_inner {
    .close_btn {
      position: absolute;
      right: 60px;
      left: auto;
      top: 60px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      border-radius: 50%;
      border: 1px solid;
    }
  }
}

@media (max-width: 1199px) {
  .header_btn,
  .header_extra {
    display: none !important;
  }
}

/** SiteNavigation(Menu) **/
.site-navigation {
  position: relative;
  z-index: 2;
}
nav.main-menu {
  ul {
    position: relative;
    margin: 0;
  }
}
#site-header-menu {
  .site-navigation {
    ul.menu {
      > li {
        > a {
          font-weight: 500;
          font-family: $headingfont;
          display: block;
          text-transform: capitalize;
          font-size: 14px;
          line-height: 1;
        }

        &:hover > {
          a {
            color: $theme-SkinColor;
          }
        }
      }
    }
  }
}
.header_social {
  margin-left: 20px !important;
  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    top: 0;
    left: -10px;
    background-color: #e4ebff;
  }

  @media (max-width: 1330px) {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  /* ttm-header-style 01*/
  .ttm-header-style-01 {
    #site-header-menu {
      .container-fluid {
        padding: 0 50px;
      }
      .site-navigation {
        .border-box-block {
          padding: 0 30px;
          margin: 0 50px;
          flex: 1 0 auto;
          border: 1px solid #e4ebff;
          border-top: 0;
          border-bottom: 0;
        }
        ul.menu {
          > li {
            > a {
              padding: 33px 20px 33px 20px;
              position: relative;
              &.active {
                color: $theme-SkinColor;
              }
            }
          }
        }
      }
    }
  }
}

/* ttm-header-style 02*/
.ttm-header-style-02 {
  @media only screen and (min-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .top_bar {
    @media only screen and (max-width: 1200px) {
      display: none !important;
    }

    position: relative;
    padding: 0 20px;
    flex-direction: row;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: -20px;
      right: -20px;
      top: auto;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      z-index: 1;
    }
  }

  #site-header-menu {
    .site-navigation {
      @media only screen and (min-width: 1200px) {
        .border-box-block {
          padding: 0 20px;
          margin: 0 40px;
          flex: 1 0 auto;
          border: 1px solid rgba(255, 255, 255, 0.08);
          border-top: 0;
          border-bottom: 0;

          @media (max-width: 1399px) {
            padding-right: 0;
            border-right: 0;
            margin-right: -20px;
          }
        }
      }
      ul.menu {
        > li {
          > a {
            padding: 33px 20px 33px 20px;
            position: relative;
            &.active {
              color: $theme-SkinColor;
            }
          }
        }
      }
      .social-icons {
        li {
          a {
            height: 28px;
            width: 28px;
            line-height: 30px;
            font-size: 11px;
            border-radius: 3px;
            display: inline-block;
            color: #fff;

            &.facebook {
              background-color: $facebook;
            }
            &.google {
              background-color: $google;
            }
            &.twitter {
              background-color: $twitter;
            }
            &.linkedin {
              background-color: $linkedin;
            }
          }
        }
      }

      .header_search {
        margin-right: 20px;
        margin-left: 0;

        .search_btn {
          display: none;
        }

        .header_search_content {
          position: unset;
          opacity: 1;
          display: block;
          height: auto;
          box-shadow: unset;
          padding: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          margin-right: 10px;
          border-radius: 3px;
          background-color: transparent;

          #searchbox {
            position: relative;
            width: auto;
            .search_query {
              border-radius: 0px;
              padding: 10px 34px 10px 10px;
              padding-right: 50px;
              font-size: 14px;
              border: 0;
              height: auto;
              width: 170px;
            }
            .close-search {
              font-size: 16px;
              margin: 0;
              right: 10px;
              line-height: 1;
            }
          }
        }
      }
      .header_btn {
        @media (max-width: 1399px) {
          display: none !important;
        }
      }
    }
  }

  &.is-Sticky {
    .top_bar {
      display: none !important;
    }
    .site-header-menu {
      background-color: $theme-DarkColor;
    }
  }
}

/* ttm-header-style 03*/
.ttm-header-style-03 {
  .top_bar {
    @media only screen and (max-width: 992px) {
      display: none !important;
    }
  }
  #site-header-menu {
    .site-navigation {
      ul.menu {
        > li {
          > a {
            padding: 38px 20px 38px 20px;
          }
        }
      }
    }
  }
  .serach_bar {
    input,
    textarea,
    select {
      width: 100%;
      height: 100%;
      padding: 14px 15px 15px;
      color: $bodyfont-Color;
      border: 0;
      border-radius: 3px;
      background-color: #fff;
    }
  }
}
